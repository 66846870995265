import { CLIENTS_LIST, CLIENT_VIEW } from '../constants'

export default [
	{
		path: CLIENTS_LIST,
		name: 'clients_list',
		component: () => import('@/views/clients/list/listView.vue'),
		meta: {
			pageTitle: 'Listado clientes',
			permission: 'list_clients',
			breadcrumb: [
				{
					text: 'Listado clientes',
					active: true,
				},
			],
		},
	},
	{
		path: `${CLIENT_VIEW}/:id`,
		name: 'clients_view',
		component: () => import('@/views/clients/sheet/sheetView.vue'),
		meta: {
			pageTitle: 'Ver cliente',
			permission: 'create_clients',
			breadcrumb: [
				{
					text: 'Clientes',
                    to: CLIENTS_LIST,
                },
				{
					text: 'Ver cliente',
					active: true,
				},
			],
		},
	},
]
