import {RMA_WORK_PART_LIST} from '../constants'

export default [
    {
        path: `${RMA_WORK_PART_LIST}/:rma_id`,
        name: 'rmas_work_part_list',
        component: () => import('@/views/workPart/list/listView.vue'),
        meta: {
            pageTitle: 'Partes de trabajo de rma',
            permission: 'list_work_part_rma',
            breadcrumb: [
                {
                    text: 'Partes de trabajo',
                    active: true,
                },
            ],
        },
    },
]
