import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./taskState"
import helpers from "@/resources/helpers/helpers"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_TASKS(state, payload) {
        state.tasks = payload
    },
    SET_TASK(state, payload) {

        Object.entries(payload).forEach(([key, value]) => {
            state.task[key] = value
        })


    },
    SET_TASK_OPERATORS(state, payload) {
        state.task.operators = payload.map(function(value) {
            return value.id
        })
    },
    SET_OPERATORS(state, payload) {
        state.operators = payload
    },
    SET_VALIDATION_ERRORS(state, payload) {
        for (const key in payload) {
            state.validation[key] = '* ' + payload[key][0]
        }
    },
    CLEAR_VALIDATION(state) {
        for (const key in state.validation) {
            state.validation[key] = null
        }
    },
}