import api from "@/router/api"

export default {
	searchTags({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.tags.search(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_TAGS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tags'
					})
				})
		})
	},
	getTag({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.tags.find(payload.id, payload)
				.then(response => {
					commit('SET_TAG', response.data.data)

					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el tag',
					})
				})
		})
	},
	getAllTags({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.tags.listAll(payload)
				.then(response => {
					const { data } = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tags.',
					})
				})
		})
	},
	getTagsByWorkcenter({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.tags.listByWorkcenter(payload)
				.then(response => {
					const { data } = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tags.',
					})
				})
		})
	},
	getTagsByClient({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.tags.listByClient(payload)
				.then(response => {
					const { data } = response.data
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tags'
					})
				})
		})
	},
	createTag({ commit }, payload) {
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.tags.create(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_TAG', data)

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateTag({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.tags.update(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_TAG', data)

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteTag({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.tags.delete(payload.id)
				.then(response => {
					
					const {data} = response.data
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tags'
					})
				})
		})
	},
}
