export const getDefaultState = () => {
	return {
		loginUserLogs: [],

		validation: {
			user_id: null,
			created_at: null,
		},
	}
}

export default getDefaultState()
