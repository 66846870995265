import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app/app'
import role from './role/role'
import user from './user/user'
import client from './client/client'
import equipment from './equipment/equipment'
import intervention from './intervention/intervention'
import tag from './tag/tag'
import notification from './notification/notification'
import loginUserLog from './loginUserLog/loginUserLog'
import userRegisterRequest from './userRegisterRequest/userRegisterRequest'
import rma from './rma/rma'
import ot from './ot/ot'
import task from './task/task'
import calendar from './calendar/calendar'
import workPart from './workPart/workPart'
import otState from './otState/otState'
import pagination from './pagination/pagination'

import auth from './auth/auth'
import appConfig from './app-config/appConfig'
import verticalMenu from './vertical-menu/verticalMenu'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import state from './state'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        auth,
        role,
        user,
        client,
        equipment,
        intervention,
        tag,
        notification,
        appConfig,
        verticalMenu,
        loginUserLog,
        userRegisterRequest,
        calendar,
        rma,
        ot,
        task,
        workPart,
        otState,
        pagination,
    },
    state,
    actions,
    getters,
    mutations,
    strict: process.env.DEV,
    namespaced: true,
})