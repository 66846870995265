import { INTERVENTIONS_LIST, INTERVENTION_VIEW } from '../constants'

export default [
	{
		path: INTERVENTIONS_LIST,
		name: 'interventions_list',
		component: () => import('@/views/interventions/list/listView.vue'),
		meta: {
			pageTitle: 'Listado intervenciones',
			permission: 'list_interventions',
			breadcrumb: [
				{
					text: 'Listado intervenciones',
					active: true,
				},
			],
		},
	},
	{
		path: `${INTERVENTIONS_LIST}/:pending`,
		name: 'interventions_pending_list',
		component: () => import('@/views/interventions/list/listView.vue'),
		meta: {
			pageTitle: 'Listado intervenciones pendientes',
			permission: 'list_interventions',
			breadcrumb: [
				{
					text: 'Listado intervenciones pendientes',
					active: true,
				},
			],
		},
	},
	{
		path: `${INTERVENTION_VIEW}/:id`,
		name: 'interventions_view',
		component: () => import('@/views/interventions/sheet/sheetView.vue'),
		meta: {
			pageTitle: 'Ver intervención',
			permission: 'see_interventions',
			breadcrumb: [
				{
					text: 'Intervención',
                    to: INTERVENTIONS_LIST,
                },
				{
					text: 'Ver intervención',
					active: true,
				},
			],
		},
	},
]
