import axios from '@/resources/libs/axios'

export default {
    find(id, data) {
        return axios.get(`/sat/tasks/find/${id}`, { params: data })
    },
    search(data) {
        return axios.get(`/sat/tasks/list`, { params: data })
    },
    create(data) {
        return axios.post(`/sat/tasks/create`, data)
    },
    update(id, data) {
        return axios.post(`/sat/tasks/update/${id}`, data)
    },
    delete(id) {
        return axios.delete(`/sat/tasks/delete/${id}`)
    },
    getAllTasks() {
        return axios.get(`/sat/tasks/getAllTasks`)
    },
}