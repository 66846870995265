import { USER_CLIENT_LIST, USER_CLIENT_EDIT } from '../constants'

export default [
	{
		path: USER_CLIENT_LIST,
		name: 'user_client_list',
		component: () => import('@/views/users/client/list/listView.vue'),
		meta: {
			pageTitle: 'Listado usuarios de GMAO',
			permission: 'list_users',
			breadcrumb: [
				{
					text: 'Listado usuarios de GMAO',
					active: true,
				},
			],
		},
	},
	{
		path: USER_CLIENT_EDIT,
		name: 'user_client_edit',
		component: () => import('@/views/users/client/form/formView.vue'),
		meta: {
			pageTitle: 'Editar usuario',
			permission: 'edit_users',
			breadcrumb: [
				{
					text: 'Editar usuario',
					active: true,
				},
			],
		},
	},
]
