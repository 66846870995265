import api from "@/router/api"

export default {
	createWorkPart({ commit }, payload) {
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.workPart.create(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_WORK_PART', data)

					resolve(data)
				})
				.catch(error => {
					const { data, status } = error.response
					const { message: title } = data

					if (status === 422) {
						commit('SET_VALIDATION_ERRORS', data.errors)
					}

					reject({ title, status })
				})
		})
	},
	updateWorkPart({ commit }, payload) {
		commit('CLEAR_VALIDATION')
		commit('CLEAR_WORKPROCESSFILES')
		return new Promise((resolve, reject) => {
			api.workPart.update(payload)
				.then(response => {
					const { data } = response.data
					//commit('SET_WORK_PART', data)

					resolve(data)
				})
				.catch(error => {
					const { data, status } = error.response
					const { message: title } = data

					console.log(data.errors)

					if (status === 422) {
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({ title, status })
				})
		})
	},
	searchWorkParts({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.search(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_WORK_PARTS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los partes de trabajo'
					})
				})
		})
	},
	getWorkPart({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.find(payload)
				.then(response => {
					commit('SET_WORK_PART', response.data.data)
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el parte de trabajo',
					})
				})
		})
	},
	getDocumentsList({commit}, payload){
		return new Promise((resolve, reject) => {
			api.workPart.getDocumentsListByRoute(payload)
				.then(response => {
					resolve(response.data.data)
					if (!payload.work_process_id)
						commit('SET_WORK_PART_DOCUMENTS', response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el listado de documentos',
					})
				})
		})
	},
	getMaterials({commit}, payload){
		return new Promise((resolve, reject) => {
			api.workPart.getMaterials(payload)
				.then(response => {
					resolve(response.data.data)

					commit('SET_LIST_MATERIALS', response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el listado de documentos',
					})
				})
		})
	},
	addMaterial({commit}, payload){
		return new Promise((resolve, reject) => {
			api.workPart.addMaterial(payload)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el listado de documentos',
					})
				})
		})
	},
	deleteDocument({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.deleteDocument(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar el registro.',
					})
				})
		})
	},
	deleteMaterial({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.deleteMaterial(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar el registro.',
					})
				})
		})
	},
	workPartStart({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.start(payload)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al inciar el parte de trabajo',
					})
				})
		})
	},
	workPartResume({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.resume(payload)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al pausar el parte de trabajo',
					})
				})
		})
	},
	workPartPause({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.pause(payload)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al pausar el parte de trabajo',
					})
				})
		})
	},
	workPartStop({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.stop(payload)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al parar el parte de trabajo',
					})
				})
		})
	},
	deleteWorkPart({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.delete(payload)
				.then(response => {
					const {data} = response.data
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar el parte de trabajo'
					})
				})
		})
	},
	getOperatorsOT({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.getOperatorsOT(payload)
				.then(response => {
					const {data} = response.data
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tecnicos'
					})
				})
		})
	},
	getOperatorsRMA({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.getOperatorsRMA(payload)
				.then(response => {
					const {data} = response.data
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tecnicos'
					})
				})
		})
	},
	getWorkProcessByType({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.getWorkProcessByType(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_WORK_PROCESS', data)

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los tecnicos'
					})
				})
		})
	},
	printPDF({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.printPDF(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({
						message
					})
				})
		})
	},
	sendWorkPartEmail({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.workPart.sendEmail(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({
						message
					})
				})
		})
	},
}
