import axios from '@/resources/libs/axios'

export default {
	find(id, data) {
		return axios.get(`/sat/clients/find/${id}`, {params: data})
	},
	search(data) {
		return axios.get(`/sat/clients/list`, {params: data})
	},
	create(data) {
		return axios.post(`/sat/clients/create`, data)
	},
	update(data) {
		return axios.post(`/sat/clients/update`, data)
	},
	delete(id) {
		return axios.delete(`/sat/clients/delete/${id}`)
	},
    listAll(data) {
        return axios.get(`/sat/clients/list/all`, {params: data})
    },
	listByWorkCenter(data) {
		return axios.get(`/sat/clients/list/workcenter`, {params: data})
	},
	getRoles(){
		return axios.get(`/sat/clients/list-roles`)
	},
	getClients(){
		return axios.get(`/wingest/get-clients`)
	},
}