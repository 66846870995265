import state from './workPartState'
import actions from './workPartActions'
import mutations from './workPartMutations'
import getters from './workPartGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
