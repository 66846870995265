import axios from '@/resources/libs/axios'

export default {
	// find(id, data) {
	// 	return axios.get(`/sat/users/find/${id}`, { params: data })
	// },
	// search(data) {
	// 	return axios.get(`/sat/users/list`, { params: data })
	// },
	// create(data) {
	// 	return axios.post(`/sat/users/create`, data)
	// },
	// delete(id) {
	// 	return axios.delete(`/sat/users/delete/${id}`)
	// },
	// listAll(data) {
	// 	return axios.get(`/sat/users/list/all`, { params: data })
	// },
}