export const getDefaultState = () => {
    return {
        clients: [],
        client: {
            id: null,
            name: null,
            address_1: null,
            address_2: null,
            postal_code: null,
            cif: null,
            phone_1: null,
            phone_2: null,
            email: null,
            work_center_id: null,
            user_id: null,

            work_center: {
                id: null,
                name: null,
                namedb: null,
            }
        },
        validation: {
            name: null,
            email: null,
            password: null,
            password_confirm: null,
        },
        search: {
            wingest_id: null,
            name: null,
            email: null,
            address_1: null,
            cif: null,
            phone_1: null,
        },
        roles: [],
    }
}

export default getDefaultState()
