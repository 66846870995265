import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./calendarState"
import helpers from "@/resources/helpers/helpers"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
}

