import state from './otStateState'
import actions from './otStateActions'
import mutations from './otStateMutations'
import getters from './otStateGetters'

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
}
