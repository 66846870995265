import api from "@/router/api"

export default {
	searchLoginUserLogs({commit}, payload){
		return new Promise((resolve, reject) => {
			api.loginUserLogs.search(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_LOGIN_USER_LOGS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los accesos'
					})
				})
		})
	},
}
