import axios from '@/resources/libs/axios'

export default {
	search(data) {
		return axios.get(`/sat/work-parts/list`, { params: data })
	},
	create(data) {
		return axios.post(`/sat/work-parts/create`, data)
	},
	update(data) {
		return axios.post(`/sat/work-parts/edit`, data)
	},
	find(data) {
		return axios.get(`/sat/work-parts/find/${data.id}`)
	},
	start(data) {
		return axios.post(`/sat/work-parts/start/${data.id}`)
	},
	pause(data) {
		return axios.post(`/sat/work-parts/pause/${data.id}`, { params: data })
	},
	resume(data) {
		return axios.post(`/sat/work-parts/resume/${data.id}`)
	},
	stop(data) {
		return axios.post(`/sat/work-parts/stop/${data.id}`)
	},
	delete(data) {
		return axios.delete(`/sat/work-parts/delete/${data.id}`)
	},
	getDocumentsListByRoute(data) {
		return axios.get(`/sat/work-parts/get-documents/${data.id}`, { params: data.work_process_id })
	},
	getMaterials(data) {
		return axios.get(`/sat/work-parts/get-materials/${data.id}`, { params: data.work_process_id })
	},
	addMaterial(data) {
		return axios.post(`/sat/work-parts/add-material/${data.id}`, { params: data })
	},
	deleteDocument(data) {
		return axios.delete(`/sat/work-parts/delete-documents/${data.id}`)
	},
	deleteMaterial(data) {
		return axios.delete(`/sat/work-parts/delete-material/${data.id}`)
	},
	getOperatorsOT(data) {
		return axios.get(`/sat/ots/operators/list`, { params: data })
	},
	getOperatorsRMA(data) {
		return axios.get(`/sat/rmas/operators/list`, { params: data })
	},
	getWorkProcessByType(data) {
		return axios.get(`/sat/work-process/list`, { params: data })
	},
	printPDF(data){
		return axios.post(`/sat/work-parts/print/${data.id}`, data, {responseType: 'blob'})
	},
	sendEmail(data) {
		return axios.post(`/sat/work-parts/send-email/${data.id}`, { params: data })
	},
}