import auth from "./modules/auth"
import roles from "./modules/roles"
import users from "./modules/users"
import clients from "./modules/clients"
import tags from "./modules/tags"
import notifications from "./modules/notifications"
import equipments from "./modules/equipments"
import interventions from "./modules/interventions"
import usersGmao from "./modules/usersGmao"
import userRegisterRequest from "./modules/userRegisterRequest"
import rmas from "./modules/rmas"
import ots from "./modules/ots"
import tasks from "./modules/tasks"
import workPart from "./modules/workPart"
import calendar from "./modules/calendar"
import otStates from "./modules/otStates"

export default {
    auth,
    roles,
    users,
    clients,
    equipments,
    interventions,
    tags,
    notifications,
    usersGmao,
    userRegisterRequest,
    rmas,
    ots,
    tasks,
    calendar,
    workPart,
    otStates,
}