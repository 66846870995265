import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./otState"
import helpers from "@/resources/helpers/helpers"

export default {
	updateField,
	RESET_STATE(state) {
		Object.assign(state, getDefaultState())
	},
	SET_OTS(state, payload) {
		state.ots = payload
	},
	SET_OT(state, payload) {
		Object.entries(payload).forEach(([key, value]) => {
			state.ot[key] = value
		})

		state.ot['date_start'] = helpers.formatDateHour(payload['date_start'])
	},
	SET_OT_OPERATORS(state, payload){
		state.ot.operators = payload.map(function (value){
			return value.id
		})
	},
	SET_OPERATORS(state, payload){
		state.operators = payload
	},
	SET_VALIDATION_ERRORS(state, payload) {
		for (const key in payload) {
			state.validation[key] = '* '+payload[key][0]
		}
	},
	CLEAR_VALIDATION(state) {
		for (const key in state.validation) {
			state.validation[key] = null
		}
	},
}

