import api from "@/router/api"

export default {
	searchUsers({commit}, payload){
		return new Promise((resolve, reject) => {
			api.users.search(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_USERS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los usuarios'
					})
				})
		})
	},
	getUser({commit}, payload){
		return new Promise((resolve, reject) => {
			api.users.find(payload.id, payload)
				.then(response => {
					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el usuario',
					})
				})
		})
	},
	createUser({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.users.create(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	createUserClient({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.users.createUserClient(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	updateUser({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.users.update(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	deleteUser({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.users.delete(payload.id)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al eliminar el usuario.',
					})
				})
		})
	},
    getAllUsers({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.users.listAll(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los usuarios.',
                    })
                })
        })
    },
    getUsersByDepartment({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.users.getUsersByDepartment(payload.id, payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los usuarios.',
                    })
                })
        })
    },
	getWorkersByDepartment({commit}, payload) {
		return new Promise((resolve, reject) => {
			api.users.getWorkersByDepartment(payload.id, payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los usuarios.',
					})
				})
		})
	},
	searchUsersClient({commit}, payload){
		return new Promise((resolve, reject) => {
			api.users.listUsersClient(payload)
				.then(response => {
					const {data} = response.data
					commit('SET_USERS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los usuarios'
					})
				})
		})
	},
	updateUserClient({commit}, payload){
		commit('CLEAR_VALIDATION')
		return new Promise((resolve, reject) => {
			api.users.updateUserClient(payload)
				.then(response => {
					const {data} = response.data

					resolve(data)
				})
				.catch(error => {
					const { data, status} = error.response
					const { message: title } = data

					if(status === 422){
						commit('SET_VALIDATION_ERRORS', data.errors)
					}
					reject({title, status})
				})
		})
	},
	getUsersByPlatformAndWorkCenter({commit}, payload) {
        return new Promise((resolve, reject) => {
            api.users.getUsersByPlatformAndWorkCenter(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(() => {
                    reject({
                        message: 'Error al obtener los usuarios.',
                    })
                })
        })
    },
	
}
