export default {
	users: [],
	validation: {
		name: null,
		email: null,
		password: null,
		roles: null,
	},
	operators: {}
}
