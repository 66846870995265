import {RMAS_LIST, RMA_VIEW} from '../constants'
import rmasWorkParts from "@/router/web/modules/rmasWorkParts"

export default [
	{
		path: RMAS_LIST,
		name: 'rmas_list',
		component: () => import('@/views/rmas/list/listView.vue'),
		meta: {
			pageTitle: 'Listado RMAS',
			permission: 'list_rmas',
			breadcrumb: [
				{
					text: 'Listado RMAS',
					active: true,
				},
			],
		},
	},
	{
		path: `${RMA_VIEW}/:id`,
		name: 'rmas_view',
		component: () => import('@/views/rmas/sheet/sheetView.vue'),
		meta: {
			pageTitle: 'Ver RMA',
			permission: 'see_rma',
			breadcrumb: [
				{
					text: 'RMAS',
                    to: RMAS_LIST,
                },
				{
					text: 'Ver RMA',
					active: true,
				},
			],
		},
	},
	...rmasWorkParts
]
