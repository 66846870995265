import axios from '@/resources/libs/axios'

export default {
    find(id, data) {
        return axios.get(`/sat/interventions/find/${id}`, {params: data})
    },
    search(data) {
        return axios.get(`/sat/interventions/list`, {params: data})
    },
    getStates() {
        return axios.get(`/gmao/interventions-states/list`)
    },
    update(data) {
        return axios.put(`/gmao/interventions/update`, data)
    },
}
