import state from './taskState'
import actions from './taskActions'
import mutations from './taskMutations'
import getters from './taskGetters'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}