import api from "@/router/api"

export default {
	searchClients({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.clients.search(payload)
				.then(response => {
					const { data } = response.data
					commit('SET_CLIENTS', data.data)
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los clientes'
					})
				})
		})
	},
	getClient({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.clients.find(payload.id, payload)
				.then(response => {
					commit('SET_CLIENT', response.data.data)

					if (response.data.data.client_personal_data) {
						commit('SET_CLIENT_PERSONAL_DATA', response.data.data.client_personal_data)
					}

					if (response.data.data.client_filter_data) {
						commit('SET_CLIENT_DATA_FILTER', response.data.data.client_filter_data)
					}

					if (response.data.data.client_epigraphs) {
						commit('SET_CLIENT_EPIGRAPHS', response.data.data.client_epigraphs)
					}

					resolve(response.data.data)
				})
				.catch(error => {
					reject({
						message: 'Ocurrió un problema al obtener el cliente',
					})
				})
		})
	},
	getAllClients({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.clients.listAll(payload)
				.then(response => {
					const { data } = response.data

					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los clientes.',
					})
				})
		})
	},

	getClientsByWorkCenter({commit}, payload){
		return new Promise((resolve, reject) => {
			api.clients.listByWorkCenter(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({message})
				})
		})
	},
	getClients({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.clients.getClients(payload)
				.then(response => {
					const { data } = response.data
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los clientes'
					})
				})
		})
	},
	getRoles({ commit }, payload) {
		return new Promise((resolve, reject) => {
			api.clients.getRoles(payload)
				.then(response => {
					const { data } = response.data
					resolve(data)
				})
				.catch(() => {
					reject({
						message: 'Error al obtener los roles de cliente'
					})
				})
		})
	},
}
