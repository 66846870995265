import api from '@/router/api'
import authService from '@/services/auth/authService'

export default {
	login({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			const {email, password} = payload
			api.auth.login(email, password)
			  .then(response => {
				  const {data} = response.data
				  data.user.permissions = data.permissions
				  data.user.workCenters = data.workCenters

				  dispatch('formalizeLogin', {
					  access_token: data.access_token,
					  expires_in: data.expires_in,
					  user: data.user,
				  })

				  resolve(response.data)
			  })
			  .catch(error => {
				  const {data: {message}} = error.response
				  // eslint-disable-next-line prefer-promise-reject-errors
				  reject({message})
			  })
		})
	},

	loginWithGivenToken({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			authService.setBearerCode(payload.access_token)

			this.dispatch('user/getAuthUser', payload)
			  .then(user => {
				  dispatch('formalizeLogin', {
					  access_token: payload.access_token,
					  expires_in: payload.expires_in,
					  user,
					  employee: user.employee,
				  })

				  resolve()
			  })
			  .catch(error => {
				  reject(error.message)
			  })
		})
	},

	updateUserData({commit, dispatch}, payload) {
		const user = JSON.parse(localStorage.getItem('userData'))
		return new Promise((resolve, reject) => {
			api.auth.updateUserData(user.id)
				.then(response => {
					const {data} = response.data
					data.user.permissions = data.permissions
					data.user.workCenters = data.workCenters

					commit('UPDATE_USER_INFO', data.user, {root: true})
					// console.log('entro')
					// console.log(data.user, data.user.workCenters[0])
					// commit('SET_WORK_CENTER', data.user.workCenters[0])

					resolve(response.data)
				})
				.catch(error => {
					// eslint-disable-next-line prefer-promise-reject-errors
					reject(error)
				})
		})
	},

	formalizeLogin({commit}, {access_token, expires_in, user}) {
		authService.setAccessToken(access_token)
		authService.setExpiry(expires_in)
		authService.setBearerCode(access_token)

		// Update user details
		commit('UPDATE_USER_INFO', user, {root: true})
	},

	logout() {
		authService.logOut()
		authService.redirectToLogin()
	},

	// eslint-disable-next-line no-unused-vars
	forgotPassword({dispatch}, email) {
		return new Promise((resolve, reject) => {
			api.forgotPassword(email)
			  .then(response => {
				  const {message, data} = response.data
				  const {status} = data

				  resolve({message, status})
			  })
			  .catch(error => {
				  const {data: {message}} = error.response
				  // eslint-disable-next-line prefer-promise-reject-errors
				  reject({message})
			  })
		})
	},

	// eslint-disable-next-line no-unused-vars
	resetPassword({dispatch}, payload) {
		return new Promise((resolve, reject) => {
			const { token, email, password, password_confirmation } = payload
			api.resetPassword(token, email, password, password_confirmation)
			  .then(response => {
				  const {message} = response.data

				  resolve({message})
			  })
			  .catch(error => {
				  const {data, status} = error.response
				  const {message: title} = data

				  let text = null
				  if (status === 422) {
					  text = Object.values(data.errors).join(' ')
				  }
				  // eslint-disable-next-line prefer-promise-reject-errors
				  reject({title, text})
			  })
		})
	},

	refresh() {
		return new Promise((resolve, reject) => {
			api.refresh()
			  .then(response => {
				  const {data} = response.data
				  authService.setAccessToken(data.access_token)
				  authService.setExpiry(data.expires_in)
				  authService.setBearerCode(data.access_token)
			  })
			  .catch(error => {
				  const {data: {message}} = error.response
				  // eslint-disable-next-line prefer-promise-reject-errors
				  reject({message})
			  })
		})
	},

	getRoles(){
		return new Promise((resolve, reject) => {
			api.roles.list()
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({message})
				})
		})
	},

	getRolesByWorkCenter({commit}, payload){
		return new Promise((resolve, reject) => {
			api.roles.listByWorkCenter(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({message})
				})
		})
	},

	getRoleNameWorkCenter({commit}, payload){
		return new Promise((resolve, reject) => {
			api.roles.getRoleNameWorkCenter(payload)
				.then(response => {
					resolve(response.data)
				})
				.catch(error => {
					const {data: {message}} = error.response
					reject({message})
				})
		})
	}
}
