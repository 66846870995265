import api from "@/router/api"

export default {
    getCalendarEvents({commit}, payload){
        return new Promise((resolve, reject) => {
            api.calendar.getCalendarEvents(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al obtener los eventos',
                    })
                })
        })
    },
    updateDateFromCalendar({commit}, payload){
        return new Promise((resolve, reject) => {
            api.calendar.updateDateFromCalendar(payload)
                .then(response => {
                    const {data} = response.data

                    resolve(data)
                })
                .catch(error => {
                    reject({
                        message: 'Ocurrió un problema al actualizar el evento',
                    })
                })
        })
    },
}
