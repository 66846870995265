import { updateField } from "vuex-map-fields"
import { getDefaultState } from "./userRegisterRequestState"

export default {
    updateField,
    RESET_STATE(state) {
        Object.assign(state, getDefaultState())
    },
    SET_USER_REGISTER_REQUEST(state, payload) {
        Object.entries(payload).forEach(([key, value]) => {
            state.userRegisterRequest[key] = value                
        })
    },
    SET_USER_REGISTER_REQUESTS(state, payload) {
        state.userRegisterRequests = payload
    },
}
